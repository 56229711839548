import React, { useState } from 'react';
import styled from 'styled-components';
import {
  EuiContextMenuItem,
  EuiButtonIcon,
  EuiIcon,
  EuiBadge,
  EuiContextMenuPanel,
  EuiPopover,
  EuiTitle,
  EuiSpacer,
  EuiButtonEmpty,
  EuiImage
} from '@elastic/eui';
import { useBreakpoints } from '../../utils/useBreakpoints';
import { singleNews } from '../../containers/layouts/BaseLayout';


const Action = styled(EuiContextMenuItem)`
  display: flex;
  align-items: center;
  padding: 12px 27px 12px 12px;
  font-weight: 500;
  &:focus, &:hover {
    background-color: transparent;
    text-decoration: none;
  }
  svg {
    margin-right: 10px;
  }
`;

const BlueAction = styled(Action)`
  color: ${({ theme }) => theme.colors.colorPrimary};
`;

const DangerAction = styled(Action)`
  color: ${({ theme }) => theme.colors.colorDanger};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -8px;
`;

const DotButton = styled(EuiButtonIcon)`
  margin-right: 10px;
`;

const Logo = styled(EuiIcon)`
  margin-right: 12px;
  @media ${({ theme }) => theme.media.phoneSmall} {
    display:none;
  }
`;

const Badge = styled(EuiBadge)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  margin-right: 12px;
  &.reminder {
    color: ${({ theme }) => theme.colors.colorBrown};
    background-color: ${({ theme }) => theme.colors.brownBackgroundLighter};
  }
  &.issue {
    color: ${({ theme }) => theme.colors.colorDanger};
    background-color: ${({ theme }) => theme.colors.dangerLighter};
  }
`;

const Time = styled.span`
  color: ${({ theme }) => theme.colors.colorDarkShade};
  margin-left: auto;
  @media ${({ theme }) => theme.media.phone} {
    font-size: 12px;
  }
`;

const ActionsButton = styled(EuiButtonIcon)`
  margin-left: auto;
  &.euiButtonIcon {
    &:hover, &:focus, &:focus-within {
      background-color: transparent;
    }
  }
`;

const ActionsPopover = styled(EuiPopover)`
  .euiButtonIcon {
    color: ${({ theme }) => theme.colors.colorDarkShade};
  }
`;

const Title = styled(EuiTitle)`
  margin-top: 14px;
`;

const Message = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  @media ${({ theme }) => theme.media.phone} {
    font-size: 14px;
    line-height: 21px;
  }
`;

const PrimaryAction = styled(EuiButtonEmpty)`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;

const SingleEventImage = styled(EuiImage)`
  margin: 15px 0 6px;
`;

const EventWrapper = styled.div`
  margin: 0 24px;
  padding: 19px 0 27px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorLightShade};
  &:last-child {
    border-bottom: none;
  }
  ${({ $isRead }) => !$isRead} {
    ${DotButton} {
      svg {
        fill: transparent; 
        stroke-width: 1px;
        stroke: ${({ theme }) => theme.colors.colorLightShade};
      }
    }
    ${Title}, ${Message} {
      color: ${({ theme }) => theme.colors.colorDarkShade};
    }
    ${Time} {
      color: ${({ theme }) => theme.colors.colorDisabledText};
    }
    ${ActionsPopover} {
      svg {
        color: ${({ theme }) => theme.colors.colorMediumShade};
      }
    }
  }
  @media ${({ theme }) => theme.media.phone} {
    margin: 0 20px;
    padding: 19px 0 23px;
  }
  
`;

export default (props) => {
  const { news, isSingleLayout, showSingleLayout, isRead } = props
  const [events, setEvents] = useState(news)
  const [openActionsPopoverMap, setOpenActionsPopoverMap] = useState([])
  const { isMobile } = useBreakpoints()

  const isActionOpen = (itemId) => {
    return openActionsPopoverMap[itemId]
  }

  const toggleActionPopover = (itemId) => {
    const newOpenActionsPopoverMap = {
      ...openActionsPopoverMap,
      ...{
        [itemId]: !openActionsPopoverMap[itemId],
      },
    };
    setOpenActionsPopoverMap(newOpenActionsPopoverMap);
  }

  const closeActionPopover = (itemId) => {
    if (isActionOpen(itemId)) {
      const newOpenActionsPopoverMap = {
        ...openActionsPopoverMap,
        ...{
          [itemId]: false,
        },
      };
      setOpenActionsPopoverMap(newOpenActionsPopoverMap);
    }
  }


  const actions = event => {
    return (
      <ActionsPopover
        zIndex={1000}
        id={`${event.id}-actions`}
        button={
          <ActionsButton
            iconType='boxesVertical'
            size='s'
            onClick={() => toggleActionPopover(event.id)}
          />
        }
        isOpen={isActionOpen(event.id)}
        closePopover={() => closeActionPopover(event.id)}
        panelPaddingSize='none'
        anchorPosition='leftCenter'
      >
        <EuiContextMenuPanel
          items={[
            <BlueAction
              icon='check'
              color=''
              key='confirm'
              onClick={() => { }} >
              Отметить как прочитанное
            </BlueAction>,
            <DangerAction
              icon='cross'
              key='reject'
              color=''
              onClick={() => { }} >
              Удалить
            </DangerAction>
          ]}
        />
      </ActionsPopover>
    )
  }

  const Event = ({ event, isSingle = false }) => {
    return (
      <EventWrapper
        key={event.id}
        $isRead={isRead}
      >
        <Header>
          <DotButton iconType='dot' />
          <Logo type={event.iconType} />
          <Badge
            color=''
            className={event.type}
          >
            {event.badge}
          </Badge >
          <Time>
            {event.time}
          </Time>
          {actions(event)}
        </Header>
        {isSingle && event.image &&
          <SingleEventImage
            alt='alt'
            src={event.image}
          />
        }
        <Title size={!isMobile ? 's' : 'xs'}>
          <h3>{event.title}</h3>
        </Title>
        {event.message &&
          <Message>
            {event.message}
          </Message>
        }
        {!isSingle && event.primaryAction &&
          <>
            <EuiSpacer size='s' />
            <PrimaryAction
              color='primary'
              size='s'
              onClick={showSingleLayout}>
              {event.primaryAction}
            </PrimaryAction>
          </>
        }
      </EventWrapper>
    )
  }

  return (
    <div>
      {!isSingleLayout
        ? events.filter(event => event.isRead === isRead).map((event) => {
          return (
            <Event event={event} />
          )
        }) : (
          <Event event={singleNews} isSingle />
        )}
    </div>
  );
};