import React, {lazy} from 'react';
import EmptyLayout from "../containers/layouts/EmptyLayout";
import BaseLayout from "../containers/layouts/BaseLayout";
import Steps from "../containers/steps/Steps";


const LoginPage = lazy(() => import("../containers/LoginPage/LoginPage"))
const PaymentsPage = lazy(() => import("../containers/PaymentsPage/PaymentsPage"))
const SettingsPage = lazy(() => import("../containers/SettingsPage/SettingsPage"))
const SubscriptionPage = lazy(() => import('../containers/SubscriptionPage/SubscriptionPage'))
const FuelPage= lazy(() => import('../containers/FuelPage/FuelPage'))
const FuelProgramPage= lazy(() => import('../containers/FuelPage/FuelProgramPage'))
const FuelDepositPage = lazy(() => import('../containers/FuelPage/FuelDepositPage'))
const ReferralPage = lazy(() => import('../containers/ReferralPage/ReferralPage'))
const DriversPage = lazy(() => import('../containers/DriversPage/DriversPage'))
const DriverPage = lazy(() => import('../containers/DriverPage/DriverPage'))
const DriversUploadContractsPage = lazy(() => import('../containers/DriversUploadContractsPage/DriversUploadContractsPage'))
const PaymentSettingsPage = lazy(() => import('../containers/PaymentSettingsPage/PaymentSettingsPage'))
const EmailVerification = lazy(() => import('../containers/EmailVerification/EmailVerification'))
const EmailVerificationSuccess = lazy(() => import('../containers/EmailVerificationSuccess/EmailVerificationSuccess'))
const ResetPassword = lazy(() => import('../containers/ResetPassword/ResetPassword'))
const StepRequisites = lazy(() => import('../containers/steps/StepRequisites/StepRequisites'))
const StepFullName = lazy(() => import('../containers/steps/StepFullName/StepFullName'))
const AnalyticsPage = lazy(() => import('../containers/AnalyticsPage/AnalyticsPage'))
const OsagoPage = lazy(() => import('../containers/OsagoPage/OsagoPage'))

const routes = [
  {
    path: '/login',
    component: EmptyLayout,
    guestOnly: true,
    routes: [
      {
        path: '/',
        component: LoginPage,
      }
    ],
  },
  {
    path: '/email/verification',
    component: EmptyLayout,
    guestOnly: true,
    routes: [
      {
        path: '/success',
        component: EmailVerificationSuccess,
      },
      {
        path: '/',
        component: EmailVerification,
      },
    ],
  },
  {
    path: '/step',
    component: Steps,
    requiresAuth: true,
    routes: [
      {
        path: '/requisites',
        component: StepRequisites,
      },
      {
        path: '/full-name',
        component: StepFullName,
      }
    ]
  },
  {
    path: '/password/reset/confirm',
    component: EmptyLayout,
    guestOnly: true,
    routes: [
      {
        path: '/',
        component: ResetPassword,
      }
    ],
  },
  {
    path: '/',
    component: BaseLayout,
    breadcrumb: {
      text: 'Главная',
    },
    requiresAuth: true,
    fillRequisites: true,
    routes: [
      {
        path: '/subscription',
        breadcrumb: {
          text: 'Подписка',
        },
        component: SubscriptionPage,
      },
      {
        path: '/fuel/program',
        breadcrumb: {
          text: 'BENZUBER',
        },
        component: FuelProgramPage,
      },
      {
        path: '/fuel/deposit',
        breadcrumb: {
          text: 'Топливо/Топливный депозит',
        },
        component: FuelPage,
      },
      {
        path: '/referral/:tab',
        component: ReferralPage,
        breadcrumb: {
          text: 'Реферальная программа',
        },
      },
      {
        path: '/referral',
        redirect: '/referral/program',
      },
      {
        path: '/payments/:tab/:id?',
        breadcrumb: {
          text: 'Платежи',
        },
        component: PaymentsPage,
      },
      {
        path: '/payments',
        redirect: '/payments/all',
      },
      {
        path: '/settings',
        // breadcrumb: {
        //   text: 'settings',
        // },
        component: SettingsPage,
        routes: [
          {
            path: '/carpark/:id?',
            query: {
              tab: 'carpark',
            },
            breadcrumb: {
              text: 'Настройки автопарка',
            },
          },
          {
            path: '/aggregators/:id?',
            query: {
              tab: 'aggregators',
            },
            breadcrumb: {
              text: 'Агрегаторы',
            }
          },
          {
            path: '/edgvr/:id?',
            query: {
              tab: 'edgvr',
            },
            breadcrumb: {
              text: 'EDGVR',
            },
          },
          {
            path: '/cloud-cashbox/:id?',
            query: {
              tab: 'cloud-cashbox',
            },
            breadcrumb: {
              text: 'Онлайн касса',
            },
          },
          {
            path: '/employees',
            breadcrumb: {
              text: 'Сотрудники',
            },
            routes: [
              {
                path: '/add-new',
                breadcrumb: {
                  text: 'Добавить сотрудника',
                },
              },
              {
                path: '/:id',
                breadcrumb: {
                  text: 'Редактировать',
                },
              },
            ]
          },
          {
            path: '/notifications',
            query: {
              tab: 'notifications',
            },
            breadcrumb: {
              text: 'Настройка уведомлений',
            },
          },
          {
            path: '/login/:id?',
            query: {
              tab: 'login',
            },
            breadcrumb: {
              text: 'Настройки входа',
            },
          },
          {
            path: '/',
            redirect: '/settings/carpark',
          },
        ],
      },
      {
        path: '/drivers/driver/:driver_id?/:tab?',
        component: DriverPage,
        breadcrumb: {
          text: 'Водители',
        },
        routes: [
          {
            path: '/:driver_id?',
          },
        ]
      },
      {
        path: '/drivers/upload-contracts',
        component: DriversUploadContractsPage,
        breadcrumb: {
          text: 'Водители',
        },
      },

      {
        path: '/drivers/payment-settings',
        breadcrumb: {
          text: 'Водители',
        },
        component: PaymentSettingsPage,
        routes: [
          {
            path: '/create-group',
            breadcrumb: {
              text: 'Настройки выплат',
            },
            routes: [
              {
                path: '/:tab',
                breadcrumb: {
                  text: 'Создать новую группу',
                },
              }
            ]
          },
          {
            path: '/edit-group',
            breadcrumb: {
              text: 'Настройки выплат',
            },
            routes: [
              {
                path: '/:tab',
                breadcrumb: {
                  text: 'Редактировать группу',
                },
              }
            ]
          },
          {
            path: '/',
            breadcrumb: {
              text: 'Настройки выплат',
            },
          }
        ]
      },

      {
        path: '/drivers/list/:tab?',
        component: DriversPage,
        breadcrumb: {
          text: 'Водители',
        },
      },

      {
        path: '/analytics/:tab?',
        component: AnalyticsPage,
        breadcrumb: {
          text: 'Аналитика',
        },
      },
      // {
      //   path: '/analytics',
      //   component: AnalyticsPage,
      //   breadcrumb: {
      //     text: 'Аналитика',
      //   },
      // },
      {
        path: '/insurance/:tab?',
        component: OsagoPage,
        breadcrumb: {
          text: 'Платежи',
        },
      },
      {
        path: '/',
        redirect: '/payments/all',
      },
    ],
  },
];

export default routes;
