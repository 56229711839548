import userActionTypes from './userActionTypes.js'
import { promiseListener } from "../store";

const userActions = {
  asyncSetPassword: promiseListener.createAsyncFunction({
    start: userActionTypes.USER_SET_PASSWORD_REQUEST,
    resolve: userActionTypes.USER_SET_PASSWORD_FULFILLED,
    reject: userActionTypes.USER_SET_PASSWORD_FAIL,
  }).asyncFunction,

  asyncAuthorization: promiseListener.createAsyncFunction({
    start: userActionTypes.USER_AUTHORIZATION_REQUEST,
    resolve: userActionTypes.USER_AUTHORIZATION_FULFILLED,
    reject: userActionTypes.USER_AUTHORIZATION_FAIL,
  }).asyncFunction,

  asyncResetPassword: promiseListener.createAsyncFunction({
    start: userActionTypes.USER_RESET_PASSWORD_REQUEST,
    resolve: userActionTypes.USER_RESET_PASSWORD_FULFILLED,
    reject: userActionTypes.USER_RESET_PASSWORD_FAIL,
  }).asyncFunction,

  asyncRegisterPark: promiseListener.createAsyncFunction({
    start: userActionTypes.USER_REGISTER_PARK_REQUEST,
    resolve: userActionTypes.USER_REGISTER_PARK_FULFILLED,
    reject: userActionTypes.USER_REGISTER_PARK_FAIL,
  }).asyncFunction,

  asyncConfirmEmail: promiseListener.createAsyncFunction({
    start: userActionTypes.USER_CONFIRM_EMAIL_REQUEST,
    resolve: userActionTypes.USER_CONFIRM_EMAIL_FULFILLED,
    reject: userActionTypes.USER_CONFIRM_EMAIL_FAIL,
  }).asyncFunction,

  asyncResetPasswordConfirm: promiseListener.createAsyncFunction({
    start: userActionTypes.USER_RESET_PASSWORD_CONFIRM_REQUEST,
    resolve: userActionTypes.USER_RESET_PASSWORD_CONFIRM_FULFILLED,
    reject: userActionTypes.USER_RESET_PASSWORD_CONFIRM_FAIL,
  }).asyncFunction,

  asyncResendEmailConfirmation: promiseListener.createAsyncFunction({
    start: userActionTypes.USER_RESEND_EMAIL_CONFIRMATION_REQUEST,
    resolve: userActionTypes.USER_RESEND_EMAIL_CONFIRMATION_FULFILLED,
    reject: userActionTypes.USER_RESEND_EMAIL_CONFIRMATION_FAIL,
  }).asyncFunction,

  asyncUpdateRequisites: promiseListener.createAsyncFunction({
    start: userActionTypes.USER_UPDATE_REQUISITES_REQUEST,
    resolve: userActionTypes.USER_UPDATE_REQUISITES_FULFILLED,
    reject: userActionTypes.USER_UPDATE_REQUISITES_FAIL,
  }).asyncFunction,

  asyncUpdateFullNamePosition: promiseListener.createAsyncFunction({
    start: userActionTypes.USER_UPDATE_FULL_NAME_REQUEST,
    resolve: userActionTypes.USER_UPDATE_FULL_NAME_FULFILLED,
    reject: userActionTypes.USER_UPDATE_FULL_NAME_FAIL,
  }).asyncFunction,

  authorization: ({email, password}) => {
    return {
      type: userActionTypes.USER_AUTHORIZATION_REQUEST,
      payload: {email, password},
    }
  },

  setUserData: ({ profile }) => {
    return {
      type: userActionTypes.USER_AUTHENTICATION_FULFILLED,
      payload: { profile },
    }
  },

  logout: () => {
    return {
      type: userActionTypes.USER_LOGOUT,
      payload: {},
    };
  },

  setPassword: ({email, oldPassword, newPassword}) => {
    return {
      type: userActionTypes.USER_SET_PASSWORD_REQUEST,
      payload: {email, oldPassword, newPassword},
    };
  }
}

export default userActions
