import React from 'react';
import styled from 'styled-components';
import {
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from "@elastic/eui";

const Wrapper = styled(EuiModal)`
  width: 448px;
  .euiModal__closeIcon {
    right: 20px;
    top: 25px;
    &:focus-within, &:hover {
      background-color: transparent;
    }
  }
  @media ${({ theme }) => theme.media.phone} {
    position: relative;
    border-radius: 6px;
    max-width: 448px !important;
    margin: 16px;
    width: calc(100vw - 32px) !important;
    .euiModal__flex {
      max-height: 75vh;
    }
  }
`;

export const ModalHeader = styled(EuiModalHeader)`
  padding: 24px 40px 12px 24px;
  justify-content: flex-start;
`;

export const ModalHeaderTitle = styled(EuiModalHeaderTitle)`
  @media ${({ theme }) => theme.media.phone} {
    font-size: 22px;
  }
`;

export const ModalBody = styled(EuiModalBody)`
  font-size: 16px;
  line-height: 24px;
  .euiModalBody__overflow {
    overflow-y: overlay;
    padding: 5px 24px;
  }
  @media ${({ theme }) => theme.media.phone} {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: ${({ $justifyContent }) => $justifyContent || 'flex-end'};
  padding: 16px 24px 24px;
  gap: 16px;
  flex-grow: 0;
  flex-shrink: 0;
`;


export const ModalWrapper = ({className, onClose, children}) => (<Wrapper className={className} onClose={onClose} initialFocus='[name=popswitch]'>{children}</Wrapper>)

export const Modal  = (props) => {
  const { title, body, footer, footerAlign, onClose, isVisible, headerPrepend, className } = props

  if (!isVisible) {
    return null
  }

  return (
    <ModalWrapper className={className} onClose={onClose}>
      <ModalHeader>
        {headerPrepend}
        <ModalHeaderTitle>
          <h3>{title}</h3>
        </ModalHeaderTitle>
      </ModalHeader>
      <ModalBody>
        {body}
      </ModalBody>
      <ModalFooter
        $justifyContent={footerAlign}
      >
        {footer}
      </ModalFooter>
    </ModalWrapper>
  )
}