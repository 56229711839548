import React from 'react';
import NestedRoutes from "../../utils/NestedRoutes";
import {connect, useSelector} from "react-redux";
import {Redirect, withRouter} from "react-router-dom";
import styled from "styled-components";
import {EuiPageBody} from "@elastic/eui";
import loginLogo from "../../assets/images/login-logo.png";

const PageBody = styled(EuiPageBody)`
  background-color: ${({theme}) => theme.colors.colorEmptyShade};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh / var(--page-zoom));
  padding: 20px;
`;

const FlexGrow1 = styled.div`
  flex-grow: 1;
`;

const FlexGrow2 = styled.div`
  flex-grow: 2;
`;

const LoginForm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
  > * {
    text-align: center;
    width: 100%;
  }
`;

const LoginLogo = styled.div`
  background-image: url(${loginLogo});
  width: 44px;
  height: 44px;
`;

function Steps (props) {
    const user = useSelector((state) => state.user)

    if (user.profile) {
        if (user.profile.full_name) {
            return <Redirect to={'/'}/>;
        }
    }

    return (
        <PageBody>
            <FlexGrow1/>
            <LoginLogo/>
            <NestedRoutes routes={props.routes} match={props.match}/>
            <FlexGrow2/>
        </PageBody>
    )
}

export default connect()(withRouter(Steps));