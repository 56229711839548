import React, { useState } from 'react';
import Steps from '../Steps/Steps';
import styled from 'styled-components';
import {
  EuiPopover,
} from '@elastic/eui';
import { PrimaryButton } from 'components/Buttons';

const OpenButton = styled.button`
  background-color: ${props => props.theme.colors.colorWarning};
  color: ${props => props.theme.colors.textColor};
  font-size: 12px;
  width: 65px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
`;

const PopoverBody = styled.div`
  width: 442px;
`;

const PopoverHeader = styled.div`
  padding: 24px 28px;
  line-height: 24px;
  color: ${props => props.theme.colors.titleColor};
  border-bottom: 1px solid ${props => props.theme.colors.lighterShade};
`;

const PopoverSteps = styled.div`
  padding: 16px 13px 0 16px;
`;

const PopoverFooter = styled.div`
  padding: 16px;
  border-top: 1px solid ${props => props.theme.colors.colorLightShade};
`;

export default (props) => {
  const { isInitialOpen, settingsSteps } = props
  const [isOpen, setIsOpen] = useState(isInitialOpen)
  const onButtonClick = () => {
    setIsOpen((isOpen) => !isOpen)
  };

  const close = () => {
    setIsOpen(false)
  };

  const button = (
    <OpenButton onClick={onButtonClick}>
      <strong>2 из 6</strong>
    </OpenButton>
  );

  return (
    <EuiPopover
      button={button}
      isOpen={isOpen}
      offset={17}
      repositionOnScroll
      panelPaddingSize={'none'}
      anchorPosition='downRight'
      closePopover={close}>
      <PopoverBody>
        <PopoverHeader>
          <strong>Рекомендуем выполнить первоначальную настройку личного кабинета MOZEN</strong>
        </PopoverHeader>
        <PopoverSteps>
          <Steps
            settingsSteps={settingsSteps}
          />
        </PopoverSteps>
        <PopoverFooter>
          <PrimaryButton size='m' fullWidth>Нужна помощь в настройке?</PrimaryButton>
        </PopoverFooter>
      </PopoverBody>
    </EuiPopover>
  )
}