import parkActionTypes from './parkActionTypes.js'
import { promiseListener } from "../store";
import autoregActionTypes from "../autoreg/autoregActionTypes";

const parkActions = {

  fetchTechnicalSupport: () => {
    return {
      type: parkActionTypes.PARK_TECHNICAL_SUPPORT_REQUEST,
      payload: {},
    }
  },

  updateTechnicalSupport: (values) => {
    return {
      type: parkActionTypes.PARK_UPDATE_TECHNICAL_SUPPORT_REQUEST,
      payload: {values},
    }
  },

  fetchAggregators: () => {
    return {
      type: parkActionTypes.PARK_FETCH_AGGREGATORS_REQUEST,
      payload: {},
    }
  },

  fetchDetails: () => {
    return {
      type: parkActionTypes.PARK_DETAILS_REQUEST,
      payload: {},
    }
  },

  asyncFetchAggregator: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_FETCH_AGGREGATOR_REQUEST,
    resolve: parkActionTypes.PARK_FETCH_AGGREGATOR_FULFILLED,
    reject: parkActionTypes.PARK_FETCH_AGGREGATOR_FAIL,
  }).asyncFunction,

  asyncCreateAggregator: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_CREATE_AGGREGATOR_REQUEST,
    resolve: parkActionTypes.PARK_CREATE_AGGREGATOR_FULFILLED,
    reject: parkActionTypes.PARK_CREATE_AGGREGATOR_FAIL,
  }).asyncFunction,

  asyncDeleteAggregator: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_DELETE_AGGREGATOR_REQUEST,
    resolve: parkActionTypes.PARK_DELETE_AGGREGATOR_FULFILLED,
    reject: parkActionTypes.PARK_DELETE_AGGREGATOR_FAIL,
  }).asyncFunction,

  asyncUpdateAggregator: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_UPDATE_AGGREGATOR_REQUEST,
    resolve: parkActionTypes.PARK_UPDATE_AGGREGATOR_FULFILLED,
    reject: parkActionTypes.PARK_UPDATE_AGGREGATOR_FAIL,
  }).asyncFunction,

  fetchCredentials: () => {
    return {
      type: parkActionTypes.PARK_FETCH_CREDENTIALS_REQUEST,
      payload: {},
    }
    },

  asyncUpdateCredentials: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_UPDATE_CREDENTIALS_REQUEST,
    resolve: parkActionTypes.PARK_UPDATE_CREDENTIALS_FULFILLED,
    reject: parkActionTypes.PARK_UPDATE_CREDENTIALS_FAIL,
  }).asyncFunction,

  fetchAlfaCertificate() {
    return {
      type: parkActionTypes.PARK_FETCH_ALFA_CERTIFICATE_REQUEST,
      payload: {},
    }
  },

  asyncUpdateAlfaCertificate: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_UPDATE_ALFA_CERTIFICATE_REQUEST,
    resolve: parkActionTypes.PARK_UPDATE_ALFA_CERTIFICATE_FULFILLED,
    reject: parkActionTypes.PARK_UPDATE_ALFA_CERTIFICATE_FAIL,
  }).asyncFunction,

  fetchTinkoffKey() {
    return {
      type: parkActionTypes.PARK_FETCH_TINKOFF_KEY_REQUEST,
      payload: {},
    }
  },

  asyncUpdateTinkoffKey: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_UPDATE_TINKOFF_KEY_REQUEST,
    resolve: parkActionTypes.PARK_UPDATE_TINKOFF_KEY_FULFILLED,
    reject: parkActionTypes.PARK_UPDATE_TINKOFF_KEY_FAIL,
  }).asyncFunction,

  asyncCreatePaymentOrder: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_CREATE_PAYMENT_ORDER_REQUEST,
    resolve: parkActionTypes.PARK_CREATE_PAYMENT_ORDER_FULFILLED,
    reject: parkActionTypes.PARK_CREATE_PAYMENT_ORDER_FAIL,
  }).asyncFunction,

  fetchEdgvrApiKey() {
    return {
      type: parkActionTypes.PARK_FETCH_EDGVR_API_KEY_REQUEST,
      payload: {},
    }
  },

  asyncUpdateEdgvrApiKey: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_REQUEST,
    resolve: parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_FULFILLED,
    reject: parkActionTypes.PARK_UPDATE_EDGVR_API_KEY_FAIL,
  }).asyncFunction,

  asyncDeleteEdgvrApiKey: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_DELETE_EDGVR_API_KEY_REQUEST,
    resolve: parkActionTypes.PARK_DELETE_EDGVR_API_KEY_FULFILLED,
    reject: parkActionTypes.PARK_DELETE_EDGVR_API_KEY_FAIL,
  }).asyncFunction,

  asyncFetchAllPaymentSettings: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_REQUEST,
    resolve: parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_FULFILLED,
    reject: parkActionTypes.PARK_FETCH_ALL_PAYMENT_SETTINGS_FAIL,
  }).asyncFunction,

  asyncAssignPaymentSettings: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_REQUEST,
    resolve: parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_FULFILLED,
    reject: parkActionTypes.PARK_ASSIGN_PAYMENT_SETTINGS_FAIL,
  }).asyncFunction,

  
  fetchNotifications(args) {
    return {
      type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_REQUEST,
      payload: args,
    }
  },

  asyncCreateMassNotification: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_CREATE_MASS_NOTIFICATION_REQUEST,
    resolve: parkActionTypes.PARK_CREATE_MASS_NOTIFICATION_FULFILLED,
    reject: parkActionTypes.PARK_CREATE_MASS_NOTIFICATION_FAIL,
  }).asyncFunction,

  asyncUpdatePaymentSettings: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_REQUEST,
    resolve: parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_FULFILLED,
    reject: parkActionTypes.PARK_UPDATE_PAYMENT_SETTINGS_FAIL,
  }).asyncFunction,

  asyncDeletePaymentSettings: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_REQUEST,
    resolve: parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_FULFILLED,
    reject: parkActionTypes.PARK_DELETE_PAYMENT_SETTINGS_FAIL,
  }).asyncFunction,

  asyncCreatePaymentSettings: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_CREATE_PAYMENT_SETTINGS_REQUEST,
    resolve: parkActionTypes.PARK_CREATE_PAYMENT_SETTINGS_FULFILLED,
    reject: parkActionTypes.PARK_CREATE_PAYMENT_SETTINGS_FAIL,
  }).asyncFunction,

  asyncFetchPaymentSettingsDrivers: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_REQUEST,
    resolve: parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FULFILLED,
    reject: parkActionTypes.PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FAIL,
  }).asyncFunction,

  asyncCreateReport: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_CREATE_REPORT_REQUEST,
    resolve: parkActionTypes.PARK_CREATE_REPORT_FULFILLED,
    reject: parkActionTypes.PARK_CREATE_REPORT_FAIL,
  }).asyncFunction,
  
  fetchNotificationsSettings() {
    return {
      type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_REQUEST,
      payload: {},
    }
  },
  
  asyncUpdateNotificationsSettings: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_UPDATE_NOTIFICATIONS_SETTINGS_REQUEST,
    resolve: parkActionTypes.PARK_UPDATE_NOTIFICATIONS_SETTINGS_FULFILLED,
    reject: parkActionTypes.PARK_UPDATE_NOTIFICATIONS_SETTINGS_FAIL,
  }).asyncFunction,

  fetchNotificationsSettingsTelegram() {
    return {
      type: parkActionTypes.PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST,
      payload: {},
    }
  },

  asyncSetNotificationsSettingsTelegram: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST,
    resolve: parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED,
    reject: parkActionTypes.PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL,
  }).asyncFunction,

  asyncDeleteNotificationsSettingsTelegram: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST,
    resolve: parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED,
    reject: parkActionTypes.PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL,
  }).asyncFunction,

  fetchPromo: (args) => {
    return {
      type: parkActionTypes.PARK_FETCH_PROMO_REQUEST,
      payload: args,
    };
  },

  setPromoHeight: ({height}) => {
    return {
      type: parkActionTypes.SET_PROMO_HEIGHT,
      payload: { height },
    };
  },

  asyncWithdrawToDeposit: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_REQUEST,
    resolve: parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_FULFILLED,
    reject: parkActionTypes.PARK_WITHDRAW_TO_DEPOSIT_FAIL,
  }).asyncFunction,

  asyncNotifyBot: promiseListener.createAsyncFunction({
    start: parkActionTypes.PARK_NOTIFY_BOT_REQUEST,
    resolve: parkActionTypes.PARK_NOTIFY_BOT_FULFILLED,
    reject: parkActionTypes.PARK_NOTIFY_BOT_FAIL,
  }).asyncFunction,
  savePaymentSettingsPagination(args) {
    return {
      type: parkActionTypes.PARK_SAVE_PAYMENT_SETTINGS_PAGINATION,
      payload: args,
    }
  },
  setFuelEnabled(args) {
    return {
      type: parkActionTypes.PARK_FUEL_ENABLED_REQUEST,
      payload: args,
    }
  },
}

export default parkActions
