import React, { useState, useEffect, Fragment } from 'react';
import EventList from '../EventList/EventList';
import styled from 'styled-components';
import { useBreakpoints } from '../../utils/useBreakpoints';
import {
  EuiIcon,
  EuiTabbedContent,
  EuiTitle,
  EuiHeaderSectionItemButton,
} from '@elastic/eui';
import { Flyout } from 'components/Flyouts';
import { PrimaryButton } from 'components/Buttons';

const Icon = styled(EuiIcon)`
  color: ${({ $iconColor, theme }) => $iconColor || theme.colors.colorEmptyShade};
`;

const Button = styled(EuiHeaderSectionItemButton)`
  height: 17px;
  width: 17px;
  min-width: auto;
  .euiButtonEmpty__content {
    padding: 0;
  }
  .euiHeaderSectionItemButton__notification--dot {
    top: -2px;
    right: -2px;
    width: 11px;
    height: 11px;
    stroke: ${({ theme }) => theme.colors.colorDarkestShade};
  }
`;

const Tabs = styled(EuiTabbedContent)`
  display: flex;
  flex-direction: column;
  overflow: auto;
  .euiTab {
    &:focus {
      background-color: initial;
    }
    &:focus-visible {
      outline-style: none;
    }
  }
  .euiTab__content {
    margin: 0 7px;
  }
  .euiTabs + div {
    overflow: overlay;
    &::-webkit-scrollbar {
      width: 16px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(105, 112, 125, 0.5);
      border: 6px solid transparent;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  
  @media ${({ theme }) => theme.media.phone} {
    .euiTabs::-webkit-scrollbar {
      height: 0;
    }
    .euiTab {
      border-bottom: 2px solid ${({ theme }) => theme.colors.colorLightShade};
      &:focus::before {
        display:none;
      }
    }
    .euiTab-isSelected {
      /* border-bottom: 1px solid transparent; */
      &:after {
        bottom: -2px;
      }
    }
  }
`;

const Footer = styled.div`
  padding: 20px 24px;
  background-color: ${({ theme }) => theme.colors.tableHover};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  @media ${({ theme }) => theme.media.phone} {
    background-color: transparent;
    padding: 16px 20px;
  }
`;

const FlyoutBody = styled.div`
  display: flex;
  flex-direction: column;  
  .euiNotificationEvent {
    padding: 25px 13px 28px 14px;
  }
  ${({ $isSingleLayout }) => $isSingleLayout ? 'max-height: calc(100% - 80px)' : 'height: 100%'}
`;

const CloseButton = styled(EuiIcon)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.colorPrimary};
  }
`;

const Header = styled.div`
  padding: 24px 24px 10px;
  display: flex;
  justify-content: space-between;
`;

export default (props) => {
  const { isInitialOpen, news, iconColor } = props
  const [isSidebarVisible, setIsSidebarVisible] = useState(isInitialOpen)
  const [isSingleLayout, setIsSingleLayout] = useState(false)
  const { isMobile } = useBreakpoints()

  const hideSingleLayout = () => setIsSingleLayout(false)
  const showSingleLayout = () => setIsSingleLayout(true)
  const onTabChange = tab => hideSingleLayout()

  useEffect(() => {
    hideSingleLayout()
  }, [isSidebarVisible])

  useEffect(() => {
    setIsSidebarVisible(false)
  }, [])

  const tabs = [
    {
      id: 'new',
      name: 'Новые',
      content: (
        <Fragment key='new'>
          <EventList
            news={news}
            isRead={false}
            showSingleLayout={showSingleLayout}
            isSingleLayout={isSingleLayout}
          />
          {isSingleLayout &&
            <Footer>
              <PrimaryButton
                fullWidth
                iconType='check'
                onClick={hideSingleLayout}
              >
                Отметить прочитаным
              </PrimaryButton>
            </Footer>
          }
        </Fragment>
      ),
    },
    {
      id: 'readed',
      name: 'Прочитанные',
      content: (
        <Fragment key='readed'>
          <EventList
            news={news}
            isRead={true}
            showSingleLayout={showSingleLayout}
            isSingleLayout={isSingleLayout}
          />
          {isSingleLayout &&
            <Footer>
              <PrimaryButton
                color='primary'
                fullWidth
                onClick={hideSingleLayout}
              >
                Закрыть
              </PrimaryButton>
            </Footer>
          }
        </Fragment>
      ),
    },
  ];

  let sidebar

  if (isSidebarVisible) {
    sidebar = (
      <Flyout
        ownFocus={!isMobile ? false : true}
        outsideClickCloses
        maxWidth={448}
        hideCloseButton
        onClose={() => setIsSidebarVisible(false)}>
        <FlyoutBody
          $isSingleLayout={isSingleLayout}
        >
          <Header>
            <EuiTitle>
              <h3>Уведомления</h3>
            </EuiTitle>
            <CloseButton
              type='cross'
              onClick={() => setIsSidebarVisible(false)}
            />
          </Header>
          <Tabs
            tabs={tabs}
            initialSelectedTab={tabs[0]}
            onTabClick={onTabChange}
          />
        </FlyoutBody>
      </Flyout>
    );
  }

  return (
    <>
      <Button
        notification={true}
        onMouseUp={(e) => { e.stopPropagation(); setIsSidebarVisible(true) }}
      >
        <Icon
          $iconColor={iconColor}
          type='bell'
        />
      </Button>
      {sidebar}
    </>
  );
}