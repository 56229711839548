import {all, fork} from 'redux-saga/effects'
import userSagas from "./user/userSagas";
import transactionsSagas from "./transactions/transactionsSagas";
import appSagas from "./app/appSagas";
import employeesSagas from "./employees/employeesSagas";
import parkSagas from "./park/parkSagas";
import fuelSagas from "./fuel/fuelSagas";
import subscriptionSagas from "./subscription/subscriptionSagas";
import referralSagas from "./referral/referralSagas";
import receiptSagas from "./receipt/receiptSagas";
import driversSagas from "./drivers/driversSagas";
import analyticsSagas from "./analytics/analyticsSagas";
import autoregSagas from "./autoreg/autoregSagas";

function* rootSaga() {
  yield all([
    yield fork(appSagas),
    yield fork(userSagas),
    yield fork(transactionsSagas),
    yield fork(employeesSagas),
    yield fork(parkSagas),
    yield fork(fuelSagas),
    yield fork(subscriptionSagas),
    yield fork(referralSagas),
    yield fork(receiptSagas),
    yield fork(driversSagas),
    yield fork(analyticsSagas),
    yield fork(autoregSagas),
  ])
}

export default rootSaga
