import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  EuiTitle,
  EuiText,
  EuiNotificationBadge,
  EuiListGroup,
  EuiCollapsibleNavGroup,
  EuiListGroupItem,
  EuiSpacer,
  EuiIcon,
} from '@elastic/eui';
import { store } from "store/store";
import userActions from "store/user/userActions";
import { useRouter } from "utils/useRouter";
import { matchPath } from 'react-router';
import { subscriptionStatusTypes } from 'store/subscription/model/subscriptionStatusTypes';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormat} from 'utils/currencyFormat'
import { Horizontal } from 'components/Lines';
import {DangerButton, GreenButton, PrimaryButton} from 'components/Buttons';
import crownDangerIcon from 'assets/images/crown-danger.svg'
import crownGreenIcon from 'assets/images/crown-green.svg'
import { SmallCard } from 'components/Cards';
import { transactionsActions } from 'store/transactions/transactionsActions'
import {appVersion} from "../../utils/appVerison";
import { ContentLoading } from 'components/Loadings';
import {useBreakpoints} from "../../utils/useBreakpoints";
import {bankTypes} from "../../store/park/model/bankTypes";
import Insurance from '../../assets/images/insurance.svg'
import TochkaDepositModal from "../Modals/Modal/TochkaDepositModal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const Header = styled.div`
  margin-bottom: 42px;
  min-height: 84px;
  @media ${props => props.theme.media.phone} {
    margin-bottom: 27px;
  }
`;

const HeaderTitle = styled(EuiTitle)`
  width: calc(100% + 10px);
  word-break: break-word;
  &.negative {
    color: ${props => props.theme.colors.colorDanger};
  }
`;

const HeaderSubtitle = styled(EuiText)`
  font-weight: 500;
`;

const HeaderButtons = styled.div`
  margin-top: 12px;
`

const Email = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.colorDarkShade};
  word-break: break-all;
`;

const TopNav = styled.div`
  margin: 31px -8px 32px;
`;

const BottomNav = styled.div`
  margin: 17px -8px 0;
`;

const NavGroup = styled(EuiCollapsibleNavGroup)`
  .euiCollapsibleNavGroup__children {
    padding: 0;
  }
  //.euiListGroup + .euiListGroup .euiListGroupItem__button {
  //  font-weight: 500;
  //  font-size: 14px;
  //  color: #69707D;
  //  padding-right: 0;
  //}

  .euiListGroup + .euiListGroup .euiListGroupItem__button, & .euiListGroup .euiListGroup .euiListGroupItem .euiListGroupItem__button {
    font-weight: 500;
    font-size: 14px;
    color: #69707D;
    padding-right: 0;
  }

  & .euiListGroup .euiListGroupItem {
    &__button .menu-icon {
      color: rgba(105, 112, 125, 1);
    }
  }
`;


const MenuNavList = styled(EuiListGroup)`
  .euiListGroupItem__button {
    font-weight: 700;
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  
  .euiListGroupItem--medium + .euiListGroupItem--medium {
    margin-top: 0;
  }
  
  .euiListGroupItem {
    .euiIcon:first-child {
      margin-right: 8px;
    }
    a {
      color: ${props => props.theme.colors.textColor};
      font-weight: 700;
    }
    &:hover, &:focus {
      .euiListGroupItem__button {
        text-decoration: none;
      }
    }
    
    &.euiListGroupItem-isActive {
      background-color: transparent;
      &:hover {
        background-color: rgba(211, 218, 230, 0.25);
      }
      .euiListGroupItem__button{
        color: ${props => props.theme.colors.colorPrimary};
      }
    }
  }

  & .euiListGroup .euiListGroupItem.euiListGroupItem-isActive .euiListGroupItem__button {
    //background: rgba(211, 218, 230, 0.25);
    color: ${props => props.theme.colors.colorPrimary};
  }
  
  & .euiListGroup {
    //padding-left: 23px;
    
    .euiListGroupItem {
      padding-left: 23px;

      .euiListGroupItem__label {
        //overflow: visible;
        white-space: normal;
      }
    }
  }
  
  .euiListGroupItem__label {
    width: 100%;
  }
  .rotate90 {
    transform: rotate(90deg);
  }
  svg + svg {
    order: 3;
  }
  .navExit {
    a {
      color: ${props => props.theme.colors.colorDarkShade};
    }
  }
`;

const PaymentsMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;  
  font-weight: 700;
`;

const InsuranceMenuItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
`;

const PaymentsQuantity = styled(EuiNotificationBadge)`
  height: 20px;
  width: 28px;
  line-height: 21px;
  margin-left: auto;
  cursor: pointer;
  border-radius: 3px;
`;

const InsuranceNewItem = styled(EuiNotificationBadge)`
  display: flex;
  padding: 2px 6px;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  border-radius: 3px;
  background: var(--viz-behind-text-eui-color-vis-2-behind-text, #EE789D);
`;

const Cents = styled.span`
  font-size: 14px;
`;

const TopSection = styled.div`
  flex: 2;
`;

const BottomSection = styled.div`
  flex: 1;
`;

const Version = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: ${props => props.theme.colors.colorDarkShade};
`;

const Status = styled.span`
  font-weight: 600;
`;

const Danger = styled(Status)`
  color: ${({ theme }) => theme.colors.colorDanger};
`;


const cardOptions = {
  [subscriptionStatusTypes.ACTIVE]: {
    icon: crownGreenIcon,
    description: (date) => date ? <><div>Подписка истекает {date}</div><Status>Осталось 3 дня</Status></> : '',
    footer: (router) => <GreenButton fullWidth size='s' onClick={router}>Подробнее</GreenButton>
  },
  [subscriptionStatusTypes.UNPAID]: {
    icon: crownDangerIcon,
    description: (date) => date ? <><div>Подписка истекла {date}</div><Danger>Не оплачена</Danger></> : '',
    footer: (router) => <DangerButton fullWidth size='s' onClick={router}>Подробнее</DangerButton>
  },
  [subscriptionStatusTypes.BLOCKED]: {
    icon: crownDangerIcon,
    description: (date) => date ? <><div>Подписка истекла {date}</div><Danger>Заблокирована</Danger></> : '',
    footer: (router) => <DangerButton fullWidth size='s' onClick={router}>Подробнее</DangerButton>
  },
  'EXPIRES': {
    description: (date) => date ? <><div>Подписка истекает {date}</div><Status>Осталось 3 дня</Status></> : '',
    footer: (router) => <DangerButton fullWidth size='s' onClick={router}>Подробнее</DangerButton>
  },
  [subscriptionStatusTypes.SUSPENDED]: {
    icon: crownDangerIcon,
    description: (date) => date ? <><div>Подписка истекла {date}</div><Danger>Приостановлена</Danger></> : '',
    footer: (router) => <DangerButton fullWidth size='s' onClick={router}>Подробнее</DangerButton>
  },
}

export default (props) => {
  const router = useRouter()
  const park = useSelector((state) => state.park)
  const transactions = useSelector((state) => state.transactions)
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const {isMobile} = useBreakpoints();

  const [openedMenu, setOpenedMenu] = useState(null);
  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);

  const hasMatchPath = path => !!matchPath(router.pathname, { path })
  const onRedirect = ({path, redirectPath, menu}) => () => {
    if (menu) {
      const opened = path === openedMenu ? null : path;

      setOpenedMenu(opened);

      if (opened === null) {
        return;
      }
    }

    router.history.push(redirectPath || path)
  }

  const parkDetails = park.details
  const parkDetailsData = parkDetails.data
  const parkDetailsLoading = parkDetails.loading

  const isTochkaBankType = parkDetailsData?.bank_type === bankTypes['tochka'];

  const parkCredentials = park.credentials
  const parkCredentialsData = parkCredentials.data
  const parkCredentialsLoading = parkCredentials.loading

  const balanceAmount = parkCredentialsData?.balance?.amount;

  const balanceAmountPenny = Math.abs(balanceAmount) % 100
  const balanceAmountValue = (balanceAmount > 0 ? Math.floor(balanceAmount / 100) : Math.ceil(balanceAmount / 100))?.toLocaleString('ru')

  const amount = <>₽ {balanceAmountValue}<Cents>, {balanceAmountPenny?.toString().padStart(2, '0')}</Cents></>
  let amountDescription ;

  if (balanceAmount < 0) {
    amountDescription = 'Рекомендуем пополнить баланс';
  } else {
    switch (parkDetailsData?.bank_type) {
      case bankTypes['payselection']:
        amountDescription = 'У вас на балансе в ZenPay';
        break;
      default:
        amountDescription = 'У вас на расчетном счете в банке';
        break;
    }
  }

  console.log(amountDescription, balanceAmount, 'amountDesc');

  const userEmail = user.profile?.email
  const userName = user.profile?.full_name

  const transactionsLoading = transactions.loading
  const credentialsLoading = parkCredentialsLoading || parkDetailsLoading

  const totalCounters = Object.values(transactions.counters).reduce((total, amount) => total + amount) || 0

  useEffect(() => {
    if (transactionsLoading) {
      return
    }
    dispatch(transactionsActions.fetchCounters())
  }, [])

  const paymentsLink = (
    <PaymentsMenuItem>
      Платежи
      {!!totalCounters && <PaymentsQuantity>{totalCounters}</PaymentsQuantity>}
    </PaymentsMenuItem>
  )

  const osagoNew = (
      <InsuranceMenuItem>
        Страховка
        <InsuranceNewItem>Нов.</InsuranceNewItem>
      </InsuranceMenuItem>
  )

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const Submenu = ({menu}) => {
    return (
        <MenuNavList
            flush={true}
            gutterSize="m"
        >
          {menu?.map((item, index) => {
            return (
                <EuiListGroupItem
                    label={item.label}
                    key={index}
                    isActive={item.isActive}
                    onClick={item.onClick && item.onClick(item)}
                >
                  {item.label}
                </EuiListGroupItem>
            );
          })}
        </MenuNavList>
    );
  };

  const NavList = ({navList}) => {
    return (<>
          {navList.map((item, index) => {
            let icons = [...(item?.icons || [])];

            if (item?.menu && item?.path && !isMobile) {
              icons.push(
                  <EuiIcon
                      type='arrowRight'
                      size="m"
                      className={'menu-icon ' + (openedMenu === item.path ? 'rotate90' : '')}
                  />
              )
            }

            return (
                <>
                  <EuiListGroupItem
                      key={index}
                      href={item?.href}
                      label={item.label}
                      size={item.size}
                      isActive={item.isActive}
                      onClick={item.onClick && item.onClick(item)}
                      iconType={item?.iconType}
                      icon={
                        <>
                          {icons && icons.map((icon, iconIndex) => (
                              <React.Fragment key={iconIndex}>
                                {icon}
                              </React.Fragment>
                          ))}
                        </>
                      }
                  />
                  {!isMobile && openedMenu === item.path && <Submenu menu={item?.menu}/>}
                </>
            );
          })}
        </>
    );
  }


  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleCloseNav = () => {
    setIsNavOpen(false);
  };
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const topNavList = [
    {
      label: paymentsLink,
      icons: [
        <EuiIcon type="database" />,
      ],
      size: 's',
      path: '/payments',
      isActive: hasMatchPath('/payments'),
      onClick: onRedirect,
    },
    {
      label: 'Водители',
      icons: [
        <EuiIcon type="users"/>,
      ],
      size: 's',
      path: '/drivers',
      redirectPath: '/drivers/list/all',
      isActive: hasMatchPath('/drivers'),
      onClick: onRedirect,
    },
    {
      label: 'Бонусы',
      icons: [
        <EuiIcon type="controlsHorizontal" />,
      ],
      size: 's',
      path: '/referral',
      isActive: hasMatchPath('/referral'),
      onClick: onRedirect,
    },
    {
      label: 'Аналитика',
      icons: [
        <EuiIcon type="analyzeEvent"/>,
      ],
      size: 's',
      path: '/analytics',
      redirectPath: '/analytics/income',
      isActive: hasMatchPath('/analytics'),
      onClick: onRedirect,
    },
    {
      label: 'Топливо',
      size: 's',
      path: '/fuel',
      redirectPath: '/fuel/program/list',
      onClick: onRedirect,
      isActive: hasMatchPath('/fuel'),
      icons: [
        <EuiIcon type="tear" size="m" />,
      ],
      menu: [
        {
          label: 'Benzuber',
          isActive: hasMatchPath('/fuel/program'),
          path: '/fuel/program/list',
          onClick: onRedirect
        },
        {
          label: 'Топливный депозит',
          isActive: hasMatchPath('/fuel/deposit'),
          path: '/fuel/deposit',
          onClick: onRedirect
        },
      ]
    },
    {
      label: osagoNew,
      icons: [
        <EuiIcon type={Insurance} size="m" />,
      ],
      size: 's',
      path: '/insurance',
      redirectPath: '/insurance',
      isActive: hasMatchPath('/insurance'),
      onClick: onRedirect,
    }
  ];

  const bottomNavList = [
    {
      label: 'База знаний',
      href: 'https://wiki.mozen.io',
      iconType: 'glasses',
      size: 's',
      target: '_blank'
    },
    {
      label: 'Настройки',
      iconType: 'gear',
      size: 's',
      path: '/settings',
      redirectPath: '/settings/carpark',
      isActive: hasMatchPath('/settings'),
      onClick: onRedirect,
    },
    {
      label: 'Выйти',
      href: '#',
      iconType: 'push',
      size: 's',
      className: 'navExit',
      onClick: () => () => store.dispatch(userActions.logout())
    },
  ];

  useEffect(() => {
    const active = [...topNavList, ...bottomNavList].find(({isActive}) => isActive);

    if (active && active?.path) {
      setOpenedMenu(active?.path);
    }
  }, []);


  const balanceCard = parkDetailsLoading ? (
    <SmallCard
      align='center'
      icon={<ContentLoading width='40' height='40' />}
      title={<ContentLoading height='48' width='80' />}
      footer={<ContentLoading height='32' />}
    />
  ) : (
    <SmallCard
      align='center'
      icon={cardOptions[parkDetailsData?.subscription_status]?.icon || ''}
      title={<>Баланс<br /> {currencyFormat(parkDetailsData?.deposit_balance)}</>}
      // body={cardOptions[parkDetailsData?.subscription_status].description('22.05.2020')}
      footer={cardOptions[parkDetailsData?.subscription_status].footer(() => router.history.push('/subscription'))}
    />
  )

  const credentials = credentialsLoading ? (
    <>
      <ContentLoading height='32'/>
      <EuiSpacer size='s' />
      <ContentLoading height='42'/>
    </>
  ) : (
    <>
      <HeaderTitle
        size='s'
        className={balanceAmount < 0 && 'negative'}
      >
        <h3>{balanceAmount ? amount : userName}</h3>
      </HeaderTitle>
      <EuiSpacer size='s' />
      <HeaderSubtitle size='s'>
        {balanceAmount ? amountDescription : <Email>{userEmail}</Email>}
      </HeaderSubtitle>

      {isTochkaBankType && parkCredentials?.data &&
          <HeaderButtons>
            <PrimaryButton
                onClick={() => setIsTopUpModalOpen(true)}
            >
              Пополнить счет
            </PrimaryButton>
          </HeaderButtons>
      }
    </>
  )


  return (
    <Wrapper>
      <TopSection>
        <Header>
          {credentials}
        </Header>
        <Horizontal/>
        <TopNav>
          <NavGroup
            onClose={handleCloseNav}
            isCollapsible={true}
            background="none"
          >
            <MenuNavList
              flush={true}
              gutterSize="m"
            >
              <NavList navList={topNavList} />
            </MenuNavList>
          </NavGroup>
          {/* <MenuNavList listItems={topNavList} flush={true} gutterSize='m' /> */}
        </TopNav>
      </TopSection>
      <BottomSection>
        {balanceCard}
        <EuiSpacer size='l' />
        <Horizontal />
        <BottomNav>
          <MenuNavList flush={true} gutterSize='m'>
            <NavList navList={bottomNavList} />
          </MenuNavList>
        </BottomNav>
      </BottomSection>
      <EuiSpacer size='l' />
      <Version>v {appVersion}</Version>
      <EuiSpacer size='xl' />
      {isTochkaBankType && <TochkaDepositModal isModalOpen={isTopUpModalOpen} setIsModalOpen={setIsTopUpModalOpen}/> }
    </Wrapper>
  )
};
