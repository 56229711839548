export default {
  colors: {
    colorDarkestShade: '#343741',
    colorEmptyShade: '#ffffff',
    colorDisabledText: '#abb4c4',
    colorDisabledBackground: '#f7f7f9',
    colorMediumShade: '#98a2b3',
    colorLightShade: '#d3dae6',
    colorPrimary: '#0071C2',
    colorPrimary1: '#0077CC',
    colorLightestShade: '#F5F7FA',
    colorDarkShade: '#69707D',
    colorFullShade: '#000000',
    colorVis0: '#6DCCB1',
    colorWarningText: '#8A6A0A',
    colorWarning: '#fec514',
    colorSuccessText: '#007E77',
    colorSuccess: '#027946',
    colorGrayMiddle: '#e0e5ee',
    colorAccentText: '#C4407C',
    colorDanger: '#BD271E',
    colorDarkGray: '#5A606B',
    colorDarkGray2: '#ECf0f5',
    colorAccent: '#A8376A',
    textColor: '#343741',
    titleColor: '#1a1c21',
    lighterShade: '#e9ecf3',
    primaryBlue: '#005ec4',
    graySubduedLight: '#E0E5EE',
    graySubduedLighter: '#e4e6f3',
    graySubduedMedium: '#e9edf3',
    grayLightShade: '#eef2f7',
    grayLightShade2: '#F7F8FA',
    gray1: '#f5f7fa',
    gray2: '#f5f9fd',
    gray3: '#ebedf0',
    primaryDanger: '#B4251D',
    colorDangerLight: '#FFF7F7',
    colorDangerLight2: '#F4E6E7',
    dangerLighter: '#f9eae9',
    dangerLighter2: '#fff3f2',
    primaryBackground: '#e6f1fd',
    primaryLightShade: '#f1f8ff',
    primaryBackgroundLighter: '#e5f1fa',
    primaryBackground1: '#e5f0fc',
    primaryBackground2: '#f5faff',
    primaryBackground3: '#ddecfd',
    primaryBackground4: '#F4F9FF',
    primaryBackground5: '#FFF9FA',
    primaryBackground6: '#f5fafe',
    primaryBackground7: '#E7E9F1',
    primaryBackground8: '#e5f4ff',
    colorBrown: '#8C5F00',
    brown1: '#CA6C1B',
    brown2: '#9B5D00',
    brownLight1: '#fff4dc',
    brownLight2: '#fdedcc',
    brownBackgroundLighter: '#fef7e6',
    formInputBackgroundLight: '#fbfcfd',
    greenDarken: '#00726B',
    greenLighter: '#e6f9ee',
    greenMedium: '#86CB96',
    greenShade: '#2A874C',
    greenBackground: '#e2f4f0',
    greenBackground2: '#F1FAF8',
    formBackgroundColor: '#FBFCFD',
    tableHover: '#FAFBFD',
    darkShade: '#404040',
    grayLight: '#f0f1f2',
    purple1: '#9170B8',
    purple2: '#7D54AE',
    backgroundPurple1: '#F6EDFF',
    backgroundPurple2: '#E4D5F3',
    black1: '#051022'
  },
  boxShadows: {
    slight: '0px 0.7px 1.4px rgba(109, 152, 184, 0.07), 0px 1.9px 4px rgba(109, 152, 184, 0.05), 0px 4.5px 10px rgba(109, 152, 184, 0.05)',
    flat: '0px 0px 0.8px rgba(109, 152, 184, 0.06), 0px 0px 2px rgba(109, 152, 184, 0.04), 0px 0px 5px rgba(109, 152, 184, 0.04), 0px 0px 17px rgba(109, 152, 184, 0.03)',
    bottomSmall: '0px 2px 2px rgba(152, 162, 179, 0.15), 0px 1px 5px rgba(152, 162, 179, 0.15)',
    bottomMedium: '0px 0.9px 4px -1px rgba(109, 152, 184, 0.08), 0px 2.6px 8px -1px rgba(109, 152, 184, 0.06), 0px 5.7px 12px -1px rgba(109, 152, 184, 0.05), 0px 15px 15px -1px rgba(109, 152, 184, 0.04)',
    bottomLight: '0px 0.7px 1.4px rgba(109, 152, 184, 0.07), 0px 1.9px 4px rgba(109, 152, 184, 0.05), 0px 4.5px 10px rgba(109, 152, 184, 0.05)',
    leftMedium: '0px 2.7px 9px rgba(109, 152, 184, 0.13), 0px 9.4px 24px rgba(109, 152, 184, 0.09), 0px 21.8px 43px rgba(109, 152, 184, 0.08)'
  },
  media: {
    phone: 'screen and (max-width: 768px)',
    phoneSmall: 'screen and (max-width: 360px)',
    tablet: 'screen and (min-width: 769px) and (max-width: 1024px)',
    fullScreen: 'screen and (min-width: 769px)',
    desktop: 'screen and (min-width: 1366px)'
  }
}
