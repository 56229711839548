const parkActionTypes = {
  PARK_DETAILS_REQUEST: 'PARK_DETAILS_REQUEST',
  PARK_DETAILS_FULFILLED: 'PARK_DETAILS_FULFILLED',
  PARK_DETAILS_FAIL: 'PARK_DETAILS_FAIL',

  PARK_TECHNICAL_SUPPORT_REQUEST: 'PARK_TECHNICAL_SUPPORT_REQUEST',
  PARK_TECHNICAL_SUPPORT_FULFILLED: 'PARK_TECHNICAL_SUPPORT_FULFILLED',
  PARK_TECHNICAL_SUPPORT_FAIL: 'PARK_TECHNICAL_SUPPORT_FAIL',

  PARK_UPDATE_TECHNICAL_SUPPORT_REQUEST: 'PARK_UPDATE_TECHNICAL_SUPPORT_REQUEST',
  PARK_UPDATE_TECHNICAL_SUPPORT_FULFILLED: 'PARK_UPDATE_TECHNICAL_SUPPORT_FULFILLED',
  PARK_UPDATE_TECHNICAL_SUPPORT_FAIL: 'PARK_UPDATE_TECHNICAL_SUPPORT_FAIL',

  PARK_FETCH_AGGREGATORS_REQUEST: 'PARK_FETCH_AGGREGATORS_REQUEST',
  PARK_FETCH_AGGREGATORS_FULFILLED: 'PARK_FETCH_AGGREGATORS_FULFILLED',
  PARK_FETCH_AGGREGATORS_FAIL: 'PARK_FETCH_AGGREGATORS_FAIL',

  PARK_FETCH_AGGREGATOR_REQUEST: 'PARK_FETCH_AGGREGATOR_REQUEST',
  PARK_FETCH_AGGREGATOR_FULFILLED: 'PARK_FETCH_AGGREGATOR_FULFILLED',
  PARK_FETCH_AGGREGATOR_FAIL: 'PARK_FETCH_AGGREGATOR_FAIL',

  PARK_CREATE_AGGREGATOR_REQUEST: 'PARK_CREATE_AGGREGATOR_REQUEST',
  PARK_CREATE_AGGREGATOR_FULFILLED: 'PARK_CREATE_AGGREGATOR_FULFILLED',
  PARK_CREATE_AGGREGATOR_FAIL: 'PARK_CREATE_AGGREGATOR_FAIL',

  PARK_DELETE_AGGREGATOR_REQUEST: 'PARK_DELETE_AGGREGATOR_REQUEST',
  PARK_DELETE_AGGREGATOR_FULFILLED: 'PARK_DELETE_AGGREGATOR_FULFILLED',
  PARK_DELETE_AGGREGATOR_FAIL: 'PARK_DELETE_AGGREGATOR_FAIL',

  PARK_UPDATE_AGGREGATOR_REQUEST: 'PARK_UPDATE_AGGREGATOR_REQUEST',
  PARK_UPDATE_AGGREGATOR_FULFILLED: 'PARK_UPDATE_AGGREGATOR_FULFILLED',
  PARK_UPDATE_AGGREGATOR_FAIL: 'PARK_UPDATE_AGGREGATOR_FAIL',

  PARK_FETCH_CREDENTIALS_REQUEST: 'PARK_FETCH_CREDENTIALS_REQUEST',
  PARK_FETCH_CREDENTIALS_FULFILLED: 'PARK_FETCH_CREDENTIALS_FULFILLED',
  PARK_FETCH_CREDENTIALS_FAIL: 'PARK_FETCH_CREDENTIALS_FAIL',

  PARK_UPDATE_CREDENTIALS_REQUEST: 'PARK_UPDATE_CREDENTIALS_REQUEST',
  PARK_UPDATE_CREDENTIALS_FULFILLED: 'PARK_UPDATE_CREDENTIALS_FULFILLED',
  PARK_UPDATE_CREDENTIALS_FAIL: 'PARK_UPDATE_CREDENTIALS_FAIL',

  PARK_FETCH_ALFA_CERTIFICATE_REQUEST: 'PARK_FETCH_ALFA_CERTIFICATE_REQUEST',
  PARK_FETCH_ALFA_CERTIFICATE_FULFILLED: 'PARK_FETCH_ALFA_CERTIFICATE_FULFILLED',
  PARK_FETCH_ALFA_CERTIFICATE_FAIL: 'PARK_FETCH_ALFA_CERTIFICATE_FAIL',

  PARK_UPDATE_ALFA_CERTIFICATE_REQUEST: 'PARK_UPDATE_ALFA_CERTIFICATE_REQUEST',
  PARK_UPDATE_ALFA_CERTIFICATE_FULFILLED: 'PARK_UPDATE_ALFA_CERTIFICATE_FULFILLED',
  PARK_UPDATE_ALFA_CERTIFICATE_FAIL: 'PARK_UPDATE_ALFA_CERTIFICATE_FAIL',

  PARK_FETCH_TINKOFF_KEY_REQUEST: 'PARK_FETCH_TINKOFF_KEY_REQUEST',
  PARK_FETCH_TINKOFF_KEY_FULFILLED: 'PARK_FETCH_TINKOFF_KEY_FULFILLED',
  PARK_FETCH_TINKOFF_KEY_FAIL: 'PARK_FETCH_TINKOFF_KEY_FAIL',

  PARK_UPDATE_TINKOFF_KEY_REQUEST: 'PARK_UPDATE_TINKOFF_KEY_REQUEST',
  PARK_UPDATE_TINKOFF_KEY_FULFILLED: 'PARK_UPDATE_TINKOFF_KEY_FULFILLED',
  PARK_UPDATE_TINKOFF_KEY_FAIL: 'PARK_UPDATE_TINKOFF_KEY_FAIL',

  PARK_CREATE_PAYMENT_ORDER_REQUEST: 'PARK_CREATE_PAYMENT_ORDER_REQUEST',
  PARK_CREATE_PAYMENT_ORDER_FULFILLED: 'PARK_CREATE_PAYMENT_ORDER_FULFILLED',
  PARK_CREATE_PAYMENT_ORDER_FAIL: 'PARK_CREATE_PAYMENT_ORDER_FAIL',

  PARK_FETCH_NOTIFICATIONS_TELEGRAM_BOT_REQUEST: 'PARK_FETCH_NOTIFICATIONS_TELEGRAM_BOT_REQUEST',
  PARK_FETCH_NOTIFICATIONS_TELEGRAM_BOT_FULFILLED: 'PARK_FETCH_NOTIFICATIONS_TELEGRAM_BOT_FULFILLED',
  PARK_FETCH_NOTIFICATIONS_TELEGRAM_BOT_FAIL: 'PARK_FETCH_NOTIFICATIONS_TELEGRAM_BOT_FAIL',

  PARK_FETCH_EDGVR_API_KEY_REQUEST: 'PARK_FETCH_EDGVR_API_KEY_REQUEST',
  PARK_FETCH_EDGVR_API_KEY_FULFILLED: 'PARK_FETCH_EDGVR_API_KEY_FULFILLED',
  PARK_FETCH_EDGVR_API_KEY_FAIL: 'PARK_FETCH_EDGVR_API_KEY_FAIL',

  PARK_UPDATE_EDGVR_API_KEY_REQUEST: 'PARK_UPDATE_EDGVR_API_KEY_REQUEST',
  PARK_UPDATE_EDGVR_API_KEY_FULFILLED: 'PARK_UPDATE_EDGVR_API_KEY_FULFILLED',
  PARK_UPDATE_EDGVR_API_KEY_FAIL: 'PARK_UPDATE_EDGVR_API_KEY_FAIL',

  PARK_DELETE_EDGVR_API_KEY_REQUEST: 'PARK_DELETE_EDGVR_API_KEY_REQUEST',
  PARK_DELETE_EDGVR_API_KEY_FULFILLED: 'PARK_DELETE_EDGVR_API_KEY_FULFILLED',
  PARK_DELETE_EDGVR_API_KEY_FAIL: 'PARK_DELETE_EDGVR_API_KEY_FAIL',

  PARK_FETCH_ALL_PAYMENT_SETTINGS_REQUEST: 'PARK_FETCH_ALL_PAYMENT_SETTINGS_REQUEST',
  PARK_FETCH_ALL_PAYMENT_SETTINGS_FULFILLED: 'PARK_FETCH_ALL_PAYMENT_SETTINGS_FULFILLED',
  PARK_FETCH_ALL_PAYMENT_SETTINGS_FAIL: 'PARK_FETCH_ALL_PAYMENT_SETTINGS_FAIL',

  PARK_ASSIGN_PAYMENT_SETTINGS_REQUEST: 'PARK_ASSIGN_PAYMENT_SETTINGS_REQUEST',
  PARK_ASSIGN_PAYMENT_SETTINGS_FULFILLED: 'PARK_ASSIGN_PAYMENT_SETTINGS_FULFILLED',
  PARK_ASSIGN_PAYMENT_SETTINGS_FAIL: 'PARK_ASSIGN_PAYMENT_SETTINGS_FAIL',

  PARK_FETCH_NOTIFICATIONS_REQUEST: 'PARK_FETCH_NOTIFICATIONS_REQUEST',
  PARK_FETCH_NOTIFICATIONS_FULFILLED: 'PARK_FETCH_NOTIFICATIONS_FULFILLED',
  PARK_FETCH_NOTIFICATIONS_FAIL: 'PARK_FETCH_NOTIFICATIONS_FAIL',

  PARK_CREATE_MASS_NOTIFICATION_REQUEST: 'PARK_CREATE_MASS_NOTIFICATION_REQUEST',
  PARK_CREATE_MASS_NOTIFICATION_FULFILLED: 'PARK_CREATE_MASS_NOTIFICATION_FULFILLED',
  PARK_CREATE_MASS_NOTIFICATION_FAIL: 'PARK_CREATE_MASS_NOTIFICATION_FAIL',

  PARK_UPDATE_PAYMENT_SETTINGS_REQUEST: 'PARK_UPDATE_PAYMENT_SETTINGS_REQUEST',
  PARK_UPDATE_PAYMENT_SETTINGS_FULFILLED: 'PARK_UPDATE_PAYMENT_SETTINGS_FULFILLED',
  PARK_UPDATE_PAYMENT_SETTINGS_FAIL: 'PARK_UPDATE_PAYMENT_SETTINGS_FAIL',
  
  PARK_DELETE_PAYMENT_SETTINGS_REQUEST: 'PARK_DELETE_PAYMENT_SETTINGS_REQUEST',
  PARK_DELETE_PAYMENT_SETTINGS_FULFILLED: 'PARK_DELETE_PAYMENT_SETTINGS_FULFILLED',
  PARK_DELETE_PAYMENT_SETTINGS_FAIL: 'PARK_DELETE_PAYMENT_SETTINGS_FAIL',
    
  PARK_CREATE_PAYMENT_SETTINGS_REQUEST: 'PARK_CREATE_PAYMENT_SETTINGS_REQUEST',
  PARK_CREATE_PAYMENT_SETTINGS_FULFILLED: 'PARK_CREATE_PAYMENT_SETTINGS_FULFILLED',
  PARK_CREATE_PAYMENT_SETTINGS_FAIL: 'PARK_CREATE_PAYMENT_SETTINGS_FAIL',

  PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_REQUEST: 'PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_REQUEST',
  PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FULFILLED: 'PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FULFILLED',
  PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FAIL: 'PARK_FETCH_PAYMENT_SETTINGS_DRIVERS_FAIL',
  
  PARK_CREATE_REPORT_REQUEST: 'PARK_CREATE_REPORT_REQUEST',
  PARK_CREATE_REPORT_FULFILLED: 'PARK_CREATE_REPORT_FULFILLED',
  PARK_CREATE_REPORT_FAIL: 'PARK_CREATE_REPORT_FAIL',
    
  PARK_FETCH_NOTIFICATIONS_SETTINGS_REQUEST: 'PARK_FETCH_NOTIFICATIONS_SETTINGS_REQUEST',
  PARK_FETCH_NOTIFICATIONS_SETTINGS_FULFILLED: 'PARK_FETCH_NOTIFICATIONS_SETTINGS_FULFILLED',
  PARK_FETCH_NOTIFICATIONS_SETTINGS_FAIL: 'PARK_FETCH_NOTIFICATIONS_SETTINGS_FAIL',

  PARK_UPDATE_NOTIFICATIONS_SETTINGS_REQUEST: 'PARK_UPDATE_NOTIFICATIONS_SETTINGS_REQUEST',
  PARK_UPDATE_NOTIFICATIONS_SETTINGS_FULFILLED: 'PARK_UPDATE_NOTIFICATIONS_SETTINGS_FULFILLED',
  PARK_UPDATE_NOTIFICATIONS_SETTINGS_FAIL: 'PARK_UPDATE_NOTIFICATIONS_SETTINGS_FAIL',
      
  PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST: 'PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST',
  PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED: 'PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED',
  PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL: 'PARK_FETCH_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL',
        
  PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST: 'PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST',
  PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED: 'PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED',
  PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL: 'PARK_SET_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL',
          
  PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST: 'PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_REQUEST',
  PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED: 'PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FULFILLED',
  PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL: 'PARK_DELETE_NOTIFICATIONS_SETTINGS_TELEGRAM_FAIL',

  PARK_FETCH_PROMO_REQUEST: 'PARK_FETCH_PROMO_REQUEST',
  PARK_FETCH_PROMO_FULFILLED: 'PARK_FETCH_PROMO_FULFILLED',
  PARK_FETCH_PROMO_FAIL: 'PARK_FETCH_PROMO_FAIL',

  SET_PROMO_HEIGHT: 'SET_PROMO_HEIGHT',

  PARK_WITHDRAW_TO_DEPOSIT_REQUEST: 'PARK_WITHDRAW_TO_DEPOSIT_REQUEST',
  PARK_WITHDRAW_TO_DEPOSIT_FULFILLED: 'PARK_WITHDRAW_TO_DEPOSIT_FULFILLED',
  PARK_WITHDRAW_TO_DEPOSIT_FAIL: 'PARK_WITHDRAW_TO_DEPOSIT_FAIL',

  PARK_NOTIFY_BOT_REQUEST: 'PARK_NOTIFY_BOT_REQUEST',
  PARK_NOTIFY_BOT_FULFILLED: 'PARK_NOTIFY_BOT_FULFILLED',
  PARK_NOTIFY_BOT_FAIL: 'PARK_NOTIFY_BOT_FAIL',

  PARK_SAVE_PAYMENT_SETTINGS_PAGINATION: 'PARK_SAVE_PAYMENT_SETTINGS_PAGINATION',

  PARK_FUEL_ENABLED_REQUEST: 'PARK_FUEL_ENABLED_REQUEST',
  PARK_FUEL_ENABLED_FULFILLED: 'PARK_FUEL_ENABLED_FULFILLED',
  PARK_FUEL_ENABLED_FAIL: 'PARK_FUEL_ENABLED_FAIL',
}

export default parkActionTypes
