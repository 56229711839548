import React, { useState } from 'react';
import styled from 'styled-components';
import {
  EuiSteps,
  EuiIcon,
  EuiAccordion,
  EuiText,
  EuiButtonEmpty
} from '@elastic/eui';

const Steps = styled(EuiSteps)`
  .euiStep__content {
    padding: 5px 16px 25px 28px;
  }
  .euiStep__title {
    width: 100%;
  }
  .euiStepNumber--complete {
    background-color: ${props => props.theme.colors.colorPrimary};
    color: ${props => props.theme.colors.colorEmptyShade};
  }
  .euiStepNumber__number {
    font-weight: 600;
    font-size: 14px;
  }
  @media ${props => props.theme.media.phone} {
    .euiStep__content {
      padding: 0 16px 31px 28px;
    }
    .euiAccordion-isOpen {
      margin-top: 19px;
    }
  }
  
`;

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  svg {
    transition: 150ms ease-in-out;
    color: ${props => props.theme.colors.colorMediumShade};
  }
  &.active {
    svg {
      transform: rotate(-180deg);
    }
  } 
`;

const AccordionCTA = styled(EuiButtonEmpty)`
  margin-top: 6px;
  margin-bottom: -11px;
  color: ${props => props.theme.colors.primaryBlue};
  &.accordionCTA {
    &:focus {
    background-color: transparent;
    }
  }
  @media ${props => props.theme.media.phone} {
    width: 100%;
    .euiButtonEmpty__text {
      margin: 0;
    }
    svg {
      margin-left: auto;
    }
  }
`;

export default (props) => {
  const { settingsSteps } = props
  const [trigger, setTrigger] = useState(settingsSteps.map(step => step.state));
  const onChange = (id) => {
    trigger[id] = trigger[id] === 'open' ? 'closed' : 'open';
    const newState = JSON.parse(JSON.stringify(trigger));
    setTrigger(newState);
  };

  const header = (title, id) => {
    return (
      <AccordionHeader className={`${trigger[id] === 'open' ? 'active' : ''}`} onClick={() => onChange(id)}>
        {title}<EuiIcon type='arrowDown' />
      </AccordionHeader>
    )
  }

  const steps = settingsSteps.map((step, index) => {
    return {
      title: header(step.title, index),
      children: (
        <EuiAccordion
          forceState={trigger[index]}
          id={'accordion' + index}
          arrowDisplay='none'>
          <EuiText size='s'>
            {step.content}
          </EuiText>
          <AccordionCTA
            contentProps={{
              style: {
                padding: '0'
              }
            }}
            className='accordionCTA'
            onClick={() => { }}
            iconType='arrowRight'
            iconSide='right'>
            Смотреть инструкцию
          </AccordionCTA>
        </EuiAccordion >
      ),
      status: step.status
    }
  })

  return (
    <Steps headingElement='div' titleSize='xs' steps={steps} />
  );
};