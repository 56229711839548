import React, { useState } from 'react';
import styled from 'styled-components';
import Steps from '../Steps/Steps';
import {
  EuiButton,
  EuiText,
} from '@elastic/eui';
import { RoundedCloseFlyout } from 'components/Flyouts';

const OpenButton = styled.button`
  background-color: ${props => props.theme.colors.colorWarning};
  color: ${props => props.theme.colors.textColor};
  font-size: 12px;
  width: 65px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  cursor: pointer;
`;

const SettingsWrapper = styled.div`
  padding: 20px;
  overflow: auto;
`;

const Header = styled(EuiText)`
  font-weight: 500;
  padding-bottom: 27px;
  border-bottom: 1px solid ${props => props.theme.colors.lighterShade};
  margin-bottom: 32px;
`;

const Footer = styled.div`
  padding-left: 40px;
`;

export default (props) => {
  const { isInitialOpen } = props
  const [isSettingsVisible, setIsSettingsVisible] = useState(isInitialOpen)
  let settings

  if (isSettingsVisible) {
    settings = (
      <RoundedCloseFlyout
        maxWidth={276}
        outsideClickCloses
        onClose={() => setIsSettingsVisible(false)}>
        <SettingsWrapper>
          <Header size='s'>
            <span>Рекомендуем выполнить первоначальную настройку личного кабинета MOZEN</span>
          </Header>
          <Steps {...props} />
          <Footer>
            <EuiButton size='m' fullWidth>Нужна помощь?</EuiButton>
          </Footer>
        </SettingsWrapper>
      </RoundedCloseFlyout>
    );
  }
  return (
    <>
      <OpenButton
        onClick={() => setIsSettingsVisible(true)}
      >
        <strong>2 из 6</strong>
      </OpenButton>
      {settings}
    </>
  )
}