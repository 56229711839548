
import React, { useEffect } from 'react';
import logo from 'assets/images/logo.svg';
import HeaderUserMenu from '../HeaderUserMenu/HeaderUserMenu';
import HeaderNews from '../HeaderNews/HeaderNews';
import HeaderAccountSettings from '../HeaderAccountSettings/HeaderAccountSettings';
import SideMenuFlyout from '../SideMenuFlyout/SideMenuFlyout';
import HeaderBalance from '../HeaderBalance/HeaderBalance';
import styled from 'styled-components';
import {
  EuiImage,
  EuiBreadcrumbs,
  EuiIcon,
  EuiHeader,
} from '@elastic/eui';
import HeaderAccountSettingsMobile from '../HeaderAccountSettingsMobile/HeaderAccountSettingsMobile';
import { useBreakpoints } from 'utils/useBreakpoints';
import { useRouter } from "utils/useRouter";
import routes from "configs/routes";
import { BreadcrumbsData } from "utils/BreadcrumbsData";
import { useSelector } from 'react-redux';

const Wrapper = styled(EuiHeader)`
  background-color: ${props => props.theme.colors.colorDarkestShade};
  box-shadow: none;
  border: none;
  z-index: 1002;
`;


const HeaderSection = styled.div`
  padding: 10px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
   @media ${props => props.theme.media.phone} {
    padding: 10px 16px;
  }
`;

const Breadcrumbs = styled(EuiBreadcrumbs)`
  color: ${props => props.theme.colors.colorEmptyShade};
  .euiLink {
    color: ${props => props.theme.colors.colorDisabledText};
    &:hover {
      color: ${props => props.theme.colors.colorEmptyShade};
    }
  }
  .euiBreadcrumbSeparator {
    background: #98A2B3;
  }
`;

const Logo = styled.a`
  margin-right: 92px;
  @media ${props => props.theme.media.tablet} {
    margin-right: 30px;
  }
`;

const AccountSettings = styled.div`
  margin-right: 22px;
   @media ${props => props.theme.media.phone} {
    margin-right: 12px;
  }
`;

const News = styled.div`
  margin-right: 20px;
`;

const SupportWrapper = styled.div`
  margin-right: 16px;
  cursor: pointer;
`;

const SupportIcon = styled(EuiIcon)`
  margin: 4px;
  color: ${props => props.theme.colors.colorEmptyShade};
  svg {
    display: flex;
  }
`;

const MobileMenu = styled.div`
  margin-right: 15px;
`;

const Balance = styled.div`
  margin-right: 31px;
`;

export default (props) => {
  const { isMobile, isTablet, isDesktopSmall, isDesktopMedium } = useBreakpoints()
  const { logout, settingsSteps, news, className } = props
  const user = useSelector((state) => state.user)
  const park = useSelector((state) => state.park)
  const router = useRouter()

  const parkCredentials = park.credentials
  const parkCredentialsData = parkCredentials.data
  const balanceAmount = parkCredentialsData?.balance?.amount

  const userProfile = user.profile

  const onClickLogo = () => router.history.push('/')

  const omnidesk = window.omni
  const omniWidgetApi = window.OmniWidgetApi
  const chatra = window?.Chatra

  const onClickSupport = () => {
    if (chatra) {
      chatra('openChat')
      if (chatra?._wrapper?.classList.contains('chatra--expanded')) {
        chatra('closeChat')
      }
    }
  }

  return (
    <Wrapper position='fixed' className={className}>
      <HeaderSection>
        <div>
          {(isMobile || isTablet || isDesktopSmall || isDesktopMedium) &&
            <MobileMenu>
              <SideMenuFlyout />
            </MobileMenu>
          }
          <Logo
            onClick={onClickLogo}
          >
            <EuiImage
              alt='Logo'
              src={logo}
            />
          </Logo>
          {!isMobile &&
            <BreadcrumbsData
              component={Breadcrumbs}
              routes={routes}
              match={{ path: '' }}
            />
          }
        </div>
        <div>
          {(isTablet || isDesktopSmall || isDesktopMedium) &&
            <Balance>
              <HeaderBalance
                balanceAmount={balanceAmount}
              />
            </Balance>
          }
          {/* <AccountSettings>
            {!isMobile
              ? <HeaderAccountSettings
                settingsSteps={settingsSteps}
              />
              : <HeaderAccountSettingsMobile
                settingsSteps={settingsSteps}
              />
            }
          </AccountSettings>
          <News>
            <HeaderNews
              news={news}
            />
          </News> */}
          {chatra &&
            <SupportWrapper
              onClick={onClickSupport}
            >
              <SupportIcon
                type='questionInCircle'
                size='l'
              />
            </SupportWrapper>
          }
          <HeaderUserMenu
            userProfile={userProfile}
            logout={logout}
          />
        </div>
      </HeaderSection>
    </Wrapper>
  )
}
