import yandexIconNew from 'assets/images/yandex-icon.svg';
import citymobilIcon from "assets/images/citymobil-icon.svg";
import taxovichkofIcon from 'assets/images/taxovichkof-icon.svg';
import gruzovichkofIcon from 'assets/images/gruzovichkof-icon.svg';
import uberIcon from 'assets/images/uber-icon.svg';
import bibiIcon from 'assets/images/bibi-icon.svg';
import bibiIconWhite from 'assets/images/bibi-icon-white.svg';

export const aggregatorsTypes = {
  'yandex': {
    id: 'yandex',
    label: 'Яндекс.Такси / Uber',
    translate: '',
    icons: [
        yandexIconNew,
        uberIcon
    ]
  },
  'citymobil': {
    id: 'citymobil',
    label: 'Cитимобил',
    translate: '',
    icons: [
      yandexIconNew,
      uberIcon
    ]
  },
  'taxovichkof': {
    id: 'taxovichkof',
    label: 'Таксовичкоф / Грузовичкоф / Cитимобил',
    translate: '',
    icons: [
      citymobilIcon,
      taxovichkofIcon,
      gruzovichkofIcon
    ]
  },
  'bibi': {
    id: 'bibi',
    label: 'BiBi car',
    translate: 'BiBi car',
    icons: [
        bibiIcon
    ],
    data_icons: [
        bibiIconWhite
    ]
  }
}